.BugAndFeedbackList {
  position: absolute;
  background: white;
  top: 3rem;
  left: 3rem;
  right: 3rem;
  bottom: 3rem;
  z-index: 1;
  padding: 1rem;
  font-family: "Poppins", "Open Sans", sans-serif;
  overflow: scroll;
}

.BugAndFeedbackList-search {
  border: 2px solid rgb(217, 218, 223);
  background-color: rgb(250, 251, 252);
  padding: 0.5rem;
  display: block;
  border-radius: 0.25rem;
}

.BugAndFeedbackList-search > input {
  border: none;
  background-color: rgb(250, 251, 252);
}

.BugAndFeedbackList-search > input:active,
.BugAndFeedbackList-search > input:focus {
  outline: none;
}

.BugList-li,
.BugList-tr {
  border: 1px solid rgb(217, 218, 223);
  border-bottom: none;
  cursor: pointer;
}

.BugList-li {
  padding: 1rem;
  display: flex;
  align-items: center;
}

table > thead > .BugList-tr {
  border: none;
}

.BugList-tr th {
  padding: 0 0 0.75rem;
  border: none;
  font-weight: 400;
}

.BugList-tr td {
  padding: 1rem;
}

.BugList-li svg {
  margin-left: auto;
}

.BugList-li:last-child,
.BugList-tr:last-child {
  border-bottom: 1px solid rgb(217, 218, 223);
}

.BugList-li .BugList-icon {
  background-color: rgb(212, 85, 68);
  display: flex;
  align-items: center;
  padding: 0.35rem;
  border-radius: 0.25rem;
  color: white;
  flex-shrink: 0;
}

.BugList-li:hover,
tbody > .BugList-tr:hover {
  background-color: rgb(237, 238, 238);
}

.FeedbackSelectTable > thead > tr th:nth-child(1) {
  width: 62%;
}

.FeedbackSelectTable > thead > tr th:nth-child(2) {
  width: 10%;
}

.FeedbackSelectTable > thead > tr th:nth-child(3) {
  width: 10%;
}

.FeedbackSelectTable > thead > tr th:nth-child(4) {
  width: 8%;
}

.FeedbackSelectTable > thead > tr th:nth-child(5) {
  width: 10%;
}

.ModalLinkExternal tbody tr td .Nested-Feedback-Table .POC-header,
.ModalLinkExternal tbody tr td .Nested-Feedback-Table td:first-child {
  width: 12rem;
  padding-left: 4rem;
}

.ModalLinkExternal tbody tr td .Nested-Feedback-Table .Sales-Engineer-header {
  width: 10rem;
}
