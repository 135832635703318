/*
 * Element visibility
 */
.entityVisibility {
  margin: 0 0.5em;
  width: 0.5em;
  height: 0.5em;
}
.isCompanyWide {
  color: #109cf1;
}
.isOneOff {
  color: #d1d1d1;
  display: none;
}
