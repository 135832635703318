.compressed {
  display: flex;
}

.compressed > *:not(:last-child) {
  width: 1.2em;
}

.compressed span {
  border: 1px solid white;
}
