.pointer {
  cursor: pointer;
}

.wizard-menu {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 0.5em;
}

.wizard-menu > input {
  outline: none;
  padding: 0.5em;
  width: 50%;
  border: 1px solid #b5b5b5;
  border-radius: 0.5rem;
}

.answer-options {
  display: flex;
  align-items: center;
  margin-top: 1em;
}

.answer-options > input {
  outline: none;
  padding: 0.5em;
  width: 50%;
  border: 1px solid #b5b5b5;
  border-radius: 0.5rem;
  margin-left: 0.5em;
}
