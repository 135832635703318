input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.ChecklistItem:hover,
.FeedbackItem:hover {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.ChecklistItem,
.FeedbackItem {
  cursor: pointer;
}

.ChecklistItem {
  border-bottom: 1px solid #ebeff2;
  padding: 1.25rem 0;

  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #323c47;
}

.ChecklistItem input,
.PocChecklist_header input {
  border-radius: 4px;
  justify-self: center;
}

.PocChecklist_header h2 {
  font-size: 1rem !important;
}

.PocChecklist-Button {
  border: 1px solid hsl(204, 86%, 83%);
}

.PocChecklist-Circle {
  border: 2px solid #3e8ed0;
}

.ChecklistItem input:checked {
  background-color: #109cf1;
}

.CreateChecklistItem,
.ChecklistItem,
.PocChecklist_header {
  display: grid;
  align-items: center;
  grid-template-columns: 3.75rem 1fr 2fr 1fr 1fr 3.75rem;
  border-bottom: 1px solid #ebeff2;
}

.CreateChecklistItem {
  padding: 1.25rem 0;
}

.CreateChecklistItem .CreateChecklistItem_create-button {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  background-color: #109cf1;
  border: none;
  justify-self: center;
}

.CreateChecklistItem .icon.is-small {
  width: 0.75rem;
  height: 0.75rem;
}

.PocChecklist h2 {
  padding: 0.875rem 0;
  margin: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #334d6e;
  opacity: 0.5;
}

.PocNewsfeed .subtitle {
  padding: 1.25rem 0.875rem;
  border-bottom: 1px solid #ebeff2;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #323c47;
  margin-bottom: 0;
}

.FeedbackItem {
  display: grid;
  grid-template-columns: 10rem 2fr 1fr 1fr;
  padding: 1.25rem 0;
  border-bottom: 1px solid #ebeff2;
}

.FeedbackItem .FeedbackItem_type {
  justify-self: center;
}

.FeedbackTypeBadge {
  border-width: 1px;
  border-style: solid;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-family: "Poppins", "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.FeedbackTypeBadge.FeedbackTypeBadge--is-issue {
  border-color: rgba(240, 55, 56, 0.25);
  color: rgb(240, 55, 56);
}

.FeedbackTypeBadge.FeedbackTypeBadge--is-request,
.FeedbackTypeBadge.FeedbackTypeBadge--is-question {
  border-color: rgba(60, 193, 59, 0.25);
  color: rgb(60, 193, 59);
}

.ChecklistItem_input {
  outline-color: #109cf1;
  border-color: #109cf1;
  box-shadow: none;
}
.ChecklistItem_input:disabled {
  background-color: #fff;
}
.CreateChecklistItem_input {
  outline: none;
  box-shadow: none;
  width: 100%;
  font: inherit;
}

.ChecklistItem_checkbox:focus {
  outline: none;
}

.vertical-line {
  position: absolute;
  z-index: 0 !important;
  height: 100%;
  border: solid 1px lightgray;
  margin-left: 50%;
}
.phase-color {
  border: 1px solid #109cf1;
  color: #109cf1;
}

.none-mark::-webkit-resizer {
  display: none;
}

.specDoc {
  border: 1px solid #f3eaea;
}

.pocEvaluationInfo.hero {
  text-align: center;
}
.pocEvaluationInfo.hero .hero-body {
  padding: 1em 1em 4em 1em;
}
.pocEvaluationInfo.hero .title {
  font-size: 3em;
  margin-bottom: 1em;
}

.pocSpecdocsection {
  overflow: auto;
  padding: 1em;
  border: 1px solid #cccccc;
}

.pocSpecdocsection ul,
.pocSpecdocsection ol,
.pocSpecdocsection li {
  list-style: revert;
  margin: revert;
  padding: revert;
}

.plusSearchBox a.dropdown-item {
  padding-left: 2em;
  max-width: 60em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
