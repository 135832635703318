.FooterPaginatedTable .page-size {
  outline: none;
  border: none;
  background: transparent;
}

.FooterPaginatedTable,
.FooterPaginatedTable select {
  color: #888;
}

.FooterPaginatedTable .page-change {
  cursor: pointer;
}

.FooterPaginatedTable .page-change.invisible {
  opacity: 0;
  pointer-events: none;
}
