.table-row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.table-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.table-header-item,
.table-row-item {
  flex: 3;
  min-width: 90px;
  padding: 1rem;
}

.icon-row {
  flex: 2;
  min-width: 60px;
}
.question-row {
  flex: 14;
  min-width: 420px;
}
.table-header-item.menu,
.table-row-item.menu {
  flex: 2;
}

.table-row-item.menu {
  text-align: end;
}

.answer-container {
  background: #fafafa;
  padding: 0 3em;
}

.answer-input {
  background: transparent;
  border: 1px solid #ccc;
  outline: none;
  width: 100%;
  padding: 0.5em;
}

.table-row-input {
  background: transparent;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 0.5rem;
  padding: 0.5em;
}

.drag-icon {
  position: absolute;
  left: 0;
  height: 100%;
  display: none;
}

.table-row:hover > .icon-row > .drag-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-per-page {
  outline: none;
  border: none;
}

.note-icon {
  background: none;
  outline: none;
  border: none;
  right: 15px;
}

.answer-input-error {
  border: 1px solid #ff0000;
}

.template-container {
  background: #eff5fb;
  color: #296fa8;
  font-size: 12px;
  padding: 0.4em;
  margin: 0.25rem !important;
  border-radius: 4px;
  position: relative;
  display: inline;
}

.template-close-icon {
  position: absolute;
  top: 0;
  right: 3px;
}
