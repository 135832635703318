.FeedbackConversation {
  position: absolute;
  right: 0;
  top: 0;
  width: 30%;
  bottom: -1rem;
  margin-right: -1rem;
  margin-top: -1rem;
  background-color: white;
}

.FeedbackConversation .top-right {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.FeedbackConversation form {
  margin-top: -0.5rem;
}

.FeedbackConversation .user-info img {
  width: 1.5em;
  height: 1.5em;
}

.FeedbackConversation {
  color: #868686;
}

.FeedbackConversation .user-info .username {
  font-family: "Poppins", "Open Sans", sans-serif;
  color: black;
}

.FeedbackConversation .user-info small {
  font-size: 0.7em;
  margin-top: -0.25rem;
}

.FeedbackConversation .current-user-message {
  background-color: #ecf0ff;
}
