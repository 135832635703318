.ConfirmChange {
  background: white;
  padding: 3rem;
  border-radius: 0.5rem;
  max-width: 800px;
}

.ConfirmChange .title {
  font-weight: normal;
  line-height: 1.5;
}

.ConfirmChange-actions {
  text-align: right;
}
