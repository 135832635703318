.Feedback {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  flex: 1;
}

.Feedback-table-wrapper {
  border: 1px solid rgb(240, 242, 243);
  border-radius: 0.25rem;
  padding: 0 0.5rem;
}

.Feedback-table.table.table-hover > thead > tr > th,
.Feedback-issue-table.table.table-hover > thead > tr > th,
.Feedback-table.table td,
.Feedback-issue-table.table td,
.Feedback-issue-table.table th,
.Feedback-table.table th {
  border-bottom: 1px solid rgb(240, 242, 243);
}

.Feedback-table td,
.Feedback-issue-table td {
  vertical-align: middle;
}

.Feedback-new {
  color: #aaa;
}

.Feedback-table .Order-header {
  width: 4rem;
}

.Feedback-table .POCs-header {
  width: 5rem;
}

.Feedback-table .ACV-header {
  width: 7rem;
}

.Feedback-table .Link-header {
  width: 6rem;
}

.Feedback-table .Type-header {
  width: 6rem;
}

.Feedback-table .Status-header {
  width: 6rem;
}

.Feedback-table .Priority-header {
  width: 6rem;
}
