.PocQuestions-container {
  background-color: white;
  border-radius: 0.25rem;
  position: relative;
  min-height: 10rem;
}

.PocQuestions {
  font-family: "Poppins", "Open Sans", sans-serif;
}

.PocQuestions h2.title {
  margin-bottom: 0;
}

.PocQuestionsBulkAttach > thead > tr > th:first-child {
  width: 2rem;
}

.PocQuestionsBulkAttach th,
.PocQuestionsBulkAttach td {
  vertical-align: middle;
}

.PocQuestions-table thead tr,
.PocQuestions-table tbody tr {
  border: 1px solid rgb(240, 242, 243);
}

.PocQuestions-table thead tr th,
.PocQuestions-table tbody tr td {
  border: none;
}

.PocQuestions-table thead .Answers-header,
.Questions-table > thead .Answer-header {
  width: 30%;
}

.NestedAnswerTable thead tr th:first-child,
.NestedAnswerTable tbody tr td:first-child {
  width: 3rem;
}

.PocQuestions-table thead .Usage-header,
.Questions-table thead .Usage-header,
.Questions-table thead .Win-Rate-header {
  width: 7rem;
}

.PocQuestions-table tbody tr td:nth-child(3) {
  color: #707683;
}

.PocQuestionsBulkAttach-button {
  position: absolute;
  right: 0.5rem;
  bottom: -4rem;
}

.PocQuestionsBulkAttach tr.active {
  background-color: rgb(243, 249, 254);
}

.Questions-table > :not(:first-child),
.PocQuestions-table > :not(:first-child) {
  border: none;
}

.select-filter {
  padding-right: 30px;
  min-width: 230px;
  justify-content: start;
}

.select-filter .dropdown-trigger {
  width: fit-content;
  position: relative;
  font-size: 14px;
}

.select-filter .dropdown-trigger .ml-2 {
  margin-top: 4px;
  position: absolute;
  right: -20px;
}

.dropdown-content {
  text-align: left;
}

.PocQuestions-container .table-container {
  overflow-y: unset !important;
  overflow: unset !important;
}

.PocQuestions-container .discovery-item .hidden-item {
  visibility: hidden;
}

.PocQuestions-container .discovery-item:hover .hidden-item {
  visibility: visible;
}

.discovery-item .note-icon {
  font-size: 16px;
  right: 22px;
  top: -12px;
}

.discovery-item button.is-light {
  margin-left: 10px;
}
