:root {
  --circle-size: clamp(1rem, 5vw, 2rem);
  /* --spacing: clamp(0.25rem, 2vw, 0.5rem); */
  --spacing: clamp(0rem, 2vw, 0rem);
}

/**
 * Stepper component
 * Modified CSS from https://www.ishadeed.com/article/stepper-component-html-css/
 */
.stepper {
  display: flex;
}

.stepper__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
}
.stepper__item:before {
  --size: 1.5rem;
  content: attr(data-content);
  display: block;
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  margin: 0 auto 1rem;
  padding-top: 0.3rem;
  z-index: 3;
  outline: 2px solid #9fabbb;
}

.stepper__item:not(:last-child):after {
  content: "";
  position: relative;
  top: calc(var(--circle-size) / 2);
  width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
  left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
  height: 2px;
  background-color: #9fabbb;
  order: -1;
  z-index: 2;
}
