@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

#root {
  height: 100vh;
}

html {
  font-family: "Poppins", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

.is-white-background {
  background-color: white;
}

code {
  font-family: "Courier New", monospace;
}

/*
 * Temporary Bulma overrides {{{
 */
body,
button,
.button input,
optgroup,
select,
textarea,
input {
  font-family: "Poppins", "Roboto", sans-serif;
}

.menu-list a.is-active {
  background-color: #3298dc;
}

.card {
  display: flex;
  flex-direction: column;
}

#topNavBar {
  border-bottom: 1px solid #e3e3e3;
}

.tabs.is-toggle li.is-active a {
  background-color: #dddbdb;
  border-color: transparent;
  color: black;
}

.tabs li.is-active a {
  border-bottom-color: black;
  color: black;
}

.navbar-link.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
a.navbar-item.is-active,
a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover {
  color: black;
}

@media screen and (max-width: 1023px) {
  .navbar.is-fixed-top-touch .navbar-menu,
  .navbar.is-fixed-top .navbar-menu {
    width: 20em;
    position: absolute;
    right: 0;
  }
}

.input,
.select select,
.textarea {
  background-color: #f9f7f7;
  border: 1px solid #f9f7f7;
  border-radius: 0;
  box-shadow: none;
}

.input:hover,
.select select:hover,
.textarea:hover,
.input:focus,
.select select:focus,
.textarea:focus {
  background-color: #ebecf0;
  border: 1px solid #ebecf0;
  box-shadow: none;
}

.input:focus,
.select select:focus,
.textarea:focus {
  border-color: #e3e1e1;
  box-shadow: none;
}

.select option {
  background-color: white;
}

.button.is-loading::after,
.control.is-loading::after,
.loader,
.select.is-loading::after {
  border: 2px solid #109cf1;
  border-right-color: transparent;
  border-top-color: transparent;
}

/*
 * }}} Temporary Bulma overrides
 */

/**
* RC-Tooltip overrides {{{
*/
.rc-tooltip {
  opacity: 1;
}

.rc-tooltip-inner {
  background-color: white;
  color: black;
  border: 1px solid gray;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  border-left-color: gray;
}

/**
* }}} RC-Tooltip overrides
*/

/**
* Horizontal separator {{{
*/
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0.5em 0;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid rgb(184, 182, 182);
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

/**
* }}} Horizontal separator
*/

.nice-tabs {
  min-height: 2.6em;
}

input[type="checkbox"].larger {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  flex-shrink: 0;
}

.table tr .showOnRowHover {
  visibility: hidden;
}

.table tr:hover .showOnRowHover {
  visibility: visible;
}

.scoreTooltip {
  width: 40em;
}

.user-avatar span,
.user-avatar img {
  content: attr(data-letters);
  display: inline-block;
  font-size: 0.75em;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 0.5em;
  color: white;
}

.user-avatar img {
  max-height: 1.5rem;
}

/**
 * Other
 */
a {
  color: rgb(54, 110, 179);
}

a:hover {
  color: rgb(43, 90, 148);
}

hr {
  background-color: #ebeff2;
  opacity: 1;
}

.button.is-info {
  background-color: #109cf1;
}

.button.is-delete {
  background-color: #d74c4c;
}

.button.is-compact {
  padding: 0.2em;
}

.reveal-items-on-hover .hidden-item {
  visibility: hidden;
}

.reveal-items-on-hover:hover .hidden-item {
  visibility: visible;
}

.has-text-info {
  color: #109cf1;
}

.has-border-info {
  border-color: #109cf1;
}

.is-spinning {
  animation: rotation 0.8s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.table-container.has-outline {
  border: 1px solid #dbdbdb;
  border-radius: 10px;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #eee;
}

.menu-list a.is-active {
  background-color: #e3eefd;
  color: #1b5db3;
}

.menu-list li ul a.is-active {
  border-left: 2px solid #1b5db3;
  background-color: transparent;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-auto {
  overflow: auto;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.object-contain {
  object-fit: contain;
}
.object-cover {
  object-fit: cover;
}
.object-fill {
  object-fit: fill;
}
.object-none {
  object-fit: none;
}
.object-scale-down {
  object-fit: scale-down;
}

.is-absolute {
  position: absolute;
}
.is-fixed {
  position: fixed;
}
.is-static {
  position: static;
}

.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.right-4 {
  right: 4rem;
}
.right-5 {
  right: 5rem;
}
.top-0 {
  top: 0;
}
.bottom-0 {
  bottom: 0;
}

.p-0 {
  padding: 0;
}

.z-0 {
  z-index: 0;
}
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.z-30 {
  z-index: 30;
}
.z-40 {
  z-index: 40;
}
.z-50 {
  z-index: 50;
}
.z-100 {
  z-index: 100;
}

.min-w-50 {
  min-width: 50%;
}
.min-w-100 {
  min-width: 100%;
}
.max-w-30 {
  max-width: 30%;
}
.max-w-50 {
  max-width: 50%;
}
.max-w-80 {
  max-width: 80%;
}
.w-1r {
  width: 1rem;
}
.w-1-25r {
  width: 1.25rem;
}
.w-2r {
  width: 2rem;
}
.w-4r {
  width: 4rem;
}
.w-5r {
  width: 5rem;
}
.w-6r {
  width: 6rem;
}
.w-7r {
  width: 7rem;
}
.w-8r {
  width: 8rem;
}
.w-9r {
  width: 9rem;
}
.w-10r {
  width: 10rem;
}
.w-15r {
  width: 15rem;
}
.w-18r {
  width: 18rem;
}
.w-20r {
  width: 20rem;
}
.w-25r {
  width: 25rem;
}
.w-30r {
  width: 30rem;
}
.w-40r {
  width: 40rem;
}
.w-45r {
  width: 45rem;
}
.w-50r {
  width: 50rem;
}
.w-55r {
  width: 55rem;
}
.w-75r {
  width: 75rem;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.min-w-40r {
  min-width: 40rem;
}

.min-h-50screen {
  min-height: 50vh;
}
.min-h-20r {
  min-height: 20rem;
}
.min-h-screen {
  min-height: 100vh;
}
.max-h-60screen {
  max-height: 60vh;
}
.h-2r {
  height: 0.5rem;
}
.h-4r {
  height: 1rem;
}
.h-5r {
  height: 1.25rem;
}
.h-8r {
  height: 2rem;
}
.h-10r {
  height: 2.5rem;
}
.h-12r {
  height: 3rem;
}
.h-15r {
  height: 3.75rem;
}
.h-20r {
  height: 5rem;
}
.h-30r {
  height: 7.5rem;
}
.h-40r {
  height: 10rem;
}
.h-50r {
  height: 12.5rem;
}
.h-60r {
  height: 15rem;
}
.h-70r {
  height: 17.5rem;
}
.h-80r {
  height: 20rem;
}
.h-90r {
  height: 22.5rem;
}
.h-100r {
  height: 25rem;
}
.h-screen {
  height: 99vh;
}
.h-full {
  height: 100%;
}

.max-h-10r {
  max-height: 2.5rem;
}
.max-h-50r {
  max-height: 13rem;
}
.max-h-100r {
  max-height: 25rem;
}
.max-h-screen {
  max-height: 99vh;
}

.rounded-none {
  border-radius: 0px;
}
.rounded-sm {
  border-radius: 0.125rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-2xl {
  border-radius: 1rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}

.opacity-0 {
  opacity: 0;
}
.opacity-10 {
  opacity: 0.1;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-100 {
  opacity: 1;
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.box-hover:hover {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.has-background-warning {
  background: #ffb946;
}

.has-text-warning {
  color: #ffb946;
}

.font-poppins {
  font-family: "Poppins";
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.item-completed,
.item-completed a {
  color: #b5adad;
}

.CodeMirror-scroll {
  padding: 0px;
}

.editor-toolbar.fullscreen {
  z-index: 50;
}

.keyboard-shortcut {
  /* Background and color */
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  color: rgba(0, 0, 0, 0.7);

  /* Bottom shadow */
  box-shadow: #d1d5db 0px -2px 0px inset, rgba(0, 0, 0, 0.4) 0px 1px 1px;

  /* Spacing */
  padding: 0.25rem 0.5rem;
}

.word-break {
  word-wrap: break-word;
}

svg {
  cursor: pointer;
}

.drop-shadow {
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 2%);
}

.drop-shadow-dark {
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 40%),
    0 0 0 2px rgb(10 10 10 / 2%);
}

.hidden {
  display: none;
}

.is-rounded {
  border-radius: 50%;
}
.is-arrow {
  cursor: default;
}
.has-background-transparent {
  background: transparent !important;
}
.list-none {
  list-style-type: none;
}

.my-neg-1 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.action-icon {
  cursor: pointer;
}

.action-icon:hover {
  color: #333;
}

.action-icon.disabled {
  opacity: 0;
  pointer-events: none;
}

.border-warning {
  outline: 2px solid rgb(207, 176, 62);
}
.border-grey {
  outline: 1px solid hsl(0, 0%, 71%);
}

.warning-text {
  color: rgb(207, 176, 62);
}

.disabled {
  pointer-events: none;
}

table.table tr.archived {
  background: rgba(0, 0, 0, 0.15);
  pointer-events: none;
}

.FeedbackCell-button {
  height: 1.7em;
}

.FeedbackCell-button svg {
  flex-shrink: 0;
}

.FeedbackCell-checkbox {
  display: block;
  margin-right: 0.6em;
  cursor: pointer;
}

.FeedbackCell-button.Priority {
  width: 100%;
  justify-content: flex-start;
}

.FeedbackCell-button.is-ghost {
  color: black;
  font-weight: 400;
  border: none;
}

.FeedbackCell-button.is-ghost:hover {
  background: none;
  border: none;
  color: black;
}

.FeedbackCell-button.is-ghost:active,
.FeedbackCell-button.is-ghost:focus {
  box-shadow: none;
}

.FeedbackCell-button.button.is-success.is-outlined:focus,
.FeedbackCell-button.button.is-success.is-outlined:hover {
  outline: none;
  color: #48c774;
  background: transparent;
  box-shadow: none;
  border: 1px solid rgb(212, 237, 209);
}

.FeedbackCell-button.button.is-success.is-outlined {
  border: 1px solid rgb(212, 237, 209);
}

.FeedbackCell-button.button.is-danger.is-outlined:focus,
.FeedbackCell-button.button.is-danger.is-outlined:hover {
  outline: none;
  color: #f14668;
  background: transparent;
  box-shadow: none;
  border: 1px solid rgb(244, 207, 206);
}

.FeedbackCell-button.button.is-danger.is-outlined {
  border: 1px solid rgb(244, 207, 206);
}

.FeedbackCell-button.button.is-warning.is-outlined:focus,
.FeedbackCell-button.button.is-warning.is-outlined:hover {
  outline: none;
  color: rgb(235, 188, 70);
  background: transparent;
  box-shadow: none;
  border: 1px solid rgb(250, 238, 202);
}

.FeedbackCell-button.button.is-warning.is-outlined {
  border: 1px solid rgb(250, 238, 202);
  color: rgb(235, 188, 70);
}

.FeedbackCell-button.button.is-blocker.is-outlined:focus,
.FeedbackCell-button.button.is-blocker.is-outlined:hover {
  outline: none;
  color: rgb(128, 99, 239);
  background: transparent;
  box-shadow: none;
  border: 1px solid rgb(231, 225, 251);
}

.FeedbackCell-button.button.is-blocker.is-outlined {
  background: transparent;
  border: 1px solid rgb(231, 225, 251);
  color: rgb(128, 99, 239);
}

.FeedbackCell-tag.tag.is-info {
  color: #109cf1;
  background: transparent;
  border: 1px solid rgb(201, 229, 249);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15em;
  line-height: 1.9em;
}

.FeedbackCell-modal {
  position: absolute;
  background: white;
  z-index: 1;
  border-radius: 0.25rem;
}

.FeedbackCell-tag-modal {
  width: 20vw;
}

.FeedbackCell-tag-modal-remove {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 100;
}

.FeedbackCell-tag-modal-remove svg {
  cursor: pointer;
}

.FeedbackCell-tag-modal .ReactTags__remove {
  background: white;
  border: none;
  color: #109cf1;
  line-height: 0.8rem;
}

.FeedbackCell-tag-modal .ReactTags__selected {
  margin: 0 1rem;
}

.FeedbackCell-tag-modal .ReactTags__selected .FeedbackCell-tag.tag.is-info {
  overflow: visible;
  display: inline;
  max-width: 10000px;
}

.FeedbackCell-tag-modal .ReactTags__tagInputField {
  border: none;
  border-bottom: 1px solid #818e9b;
  border-radius: 0;
  width: 90%;
  padding: 0.25em;
  margin-bottom: 0.25em;
}

.FeedbackCell-tag-modal .ReactTags__tagInputField:focus {
  outline: none;
}

.FeedbackCell-modal li {
  margin: 0.25rem 0;
  padding: 0.25rem 1rem;
  cursor: pointer;
}

.FeedbackCell-modal li:hover {
  background: rgb(236, 236, 236);
}

.comment-count a {
  color: #888;
}

.comment-count a:hover {
  color: #666;
}

.Questions-table th,
.Questions-table td,
.Usecases-table th,
.Usecases-table td {
  border: none;
}

.PocQuestionsBulkAttach > thead > tr > th.Answer-header {
  width: 16rem;
}

.PocQuestionsBulkAttach > thead > tr > th.Usage-header,
.PocQuestionsBulkAttach > thead > tr > th.Win-Rate-header {
  width: 9rem;
}

.Questions-table > thead > tr > th,
.Usecases-table > thead > tr > th {
  border: none;
}

.Questions-table > tbody > tr > td:nth-child(3),
.Usecases-table > tbody > tr > td:nth-child(3) {
  color: rgb(113, 118, 130);
  padding-left: 1rem;
}

.Questions-table,
.Usecases-table {
  border-collapse: collapse;
  border-radius: 0.5rem;
  border-style: hidden;
  box-shadow: 0 0 0 1px rgb(240, 242, 243);
}

.Questions-table tbody tr,
.Usecases-table tbody tr {
  border-top: 1px solid rgb(240, 242, 243);
}

.Questions-table th,
.Usecases-table th,
.Questions-table td,
.Usecases-table td {
  vertical-align: middle;
}

.PocFeedback-table .react-datepicker-wrapper {
  max-width: 6em;
}
.react-datepicker-wrapper input {
  text-align: center;
}

.dimmed-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.note-mark {
  content: "";
  border-style: solid;
  border-width: 0 1rem 1rem 0;
  border-color: transparent #109cf1 transparent transparent;
  background-color: transparent;
  position: absolute;
  right: 1rem;
  top: 0;
}

.CodeMirror,
.CodeMirror-scroll {
  max-height: 20rem;
}
.CodeMirror-fullscreen.CodeMirror {
  max-height: none;
}
.CodeMirror-fullscreen .CodeMirror-scroll {
  max-height: none;
}

.bulma-loader-mixin {
  -webkit-animation: spinAround 0.5s infinite linear;
  animation: spinAround 0.5s infinite linear;
  border: 2px solid #dbdbdb;
  border-radius: 9999px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
}

.loading-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
}
.tox-tinymce {
  margin-bottom: 0;
}

.dropdown-menu {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

:root {
  --circle-size: clamp(1rem, 5vw, 2rem);
  /* --spacing: clamp(0.25rem, 2vw, 0.5rem); */
  --spacing: clamp(0rem, 2vw, 0rem);
}

/**
 * Stepper component
 * Modified CSS from https://www.ishadeed.com/article/stepper-component-html-css/
 */
.c-stepper {
  display: flex;
}

.c-stepper__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
}
.c-stepper__item:before {
  --size: 1.5rem;
  content: "";
  display: block;
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  margin: 0 auto 1rem;
  padding-top: 0.3rem;
  z-index: 3;
  outline: 2px solid #e0e0e0;
}
.c-stepper__item:nth-child(1):before {
  content: "1";
}
.c-stepper__item:nth-child(2):before {
  content: "2";
}
.c-stepper__item:nth-child(3):before {
  content: "3";
}
.c-stepper__item:nth-child(4):before {
  content: "4";
}

.c-stepper__item:not(:last-child):after {
  content: "";
  position: relative;
  top: calc(var(--circle-size) / 2);
  width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
  left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
  height: 2px;
  background-color: #e0e0e0;
  order: -1;
  z-index: 2;
}

.c-stepper__item.completed:before {
  background-color: #109cf1;
  outline: 0;
  content: "✓" !important;
  color: white;
  font-weight: bold;
}
.c-stepper__item.completed:after {
  background-color: #109cf1;
}

.c-stepper__item.active:before {
  outline: 2px solid #109cf1;
}

.c-stepper__item.active {
  font-weight: bold;
}

.c-stepper__title {
  /* font-size: clamp(1rem, 4vw, 1.25rem); */
  margin-bottom: 0.5rem;
}
