input.error,
select.error {
  border: 1px solid rgb(223, 70, 62);
}

input.error:focus,
select.error:focus {
  box-shadow: 0 0 0 0.2rem rgb(226, 107, 102);
  border-color: rgb(223, 70, 62);
}

.error-message {
  color: rgb(223, 70, 62);
  font-size: 0.8rem;
  margin-top: 0.1rem;
  position: absolute;
}

input.warning,
select.warning {
  border: 1px solid rgb(207, 176, 62);
}

input.warning:focus,
select.warning:focus {
  box-shadow: 0 0 0 0.2rem rgb(233, 198, 70);
  border-color: rgb(207, 176, 62);
}
