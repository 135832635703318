.Attachment {
  background-color: white;
  padding: 1rem;
  /* border-radius: 0.5rem; */
  flex: 1;
}

.Attachment-table-wrapper {
  /* border: 1px solid rgb(240, 242, 243); */
  /* border-radius: 0.25rem; */
  padding: 0 0.5rem;
}

.Attachment-table.table.table-hover > thead > tr > th,
.Attachment-issue-table.table.table-hover > thead > tr > th,
.Attachment-table.table td,
.Attachment-issue-table.table td,
.Attachment-issue-table.table th,
.Attachment-table.table th {
  /* border-bottom: 1px solid rgb(240, 242, 243); */
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 2.5rem !important;
  border-bottom: none !important;
  outline: none !important;
}

.Attachment-table td,
.Attachment-issue-table td {
  vertical-align: middle;
}

.Attachment-new {
  color: #aaa;
}

.Attachment-table .Name-header {
  width: 40%;
}
.Attachment-table .Date-Modified-header {
  width: 20%;
}
.Attachment-table .Type-header {
  width: 30%;
}
