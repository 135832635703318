.Table .Table-expand-column {
  width: 1rem;
}

.Table.table-hover > tbody > tr.Table-no-hover:hover {
  --bs-table-accent-bg: white;
}

.Table th,
.Table td {
  vertical-align: middle;
  font-size: 1rem !important;
}

.Table > :not(:first-child) {
  border: none;
}

.Table-head-hidden {
  opacity: 0 !important;
  border: 0 none !important;
  height: 0 !important;
}

.Table-head-hidden * {
  margin: 0 !important;
  padding: 0 !important;
  border: 0 none !important;
  height: 0px !important;
}
.Actions-header {
  width: 6rem !important;
}

.Table tr:hover .show-on-row-hover {
  visibility: visible;
}
.Table tr td .show-on-row-hover {
  visibility: hidden;
}
