.PocFeedback {
  position: relative;
  height: 100%;
  border: 1px solid rgb(240, 242, 243);
  border-radius: 0.25rem;
  margin: 1rem;
}

/* .PocFeedback-table .active-dropdown {
  margin-bottom: 8rem;
} */

.PocFeedback-table.table.table-hover > thead > tr > th,
.PocFeedback-table.table td,
.PocFeedback-table.table th {
  border-bottom: 1px solid rgb(240, 242, 243);
}

.PocFeedback-table td {
  vertical-align: middle;
}

/* .PocFeedback-table th,
.PocFeedback-table td {
  overflow: hidden;
} */

.PocFeedback-table.prospect th:nth-child(3) {
  width: 60%;
}

.PocFeedback-table .form-group {
  margin-bottom: 0 !important;
}

.PocFeedback-table .react-datepicker__input-container .form-control {
  border: 0;
  padding: 0;
}

.PocFeedback-table .Feedback-header {
  min-width: 20em;
}
