.AddToSalesForceModal {
  position: absolute;
  right: 7rem;
  background: white;
  border-radius: 0.25rem;
  transform: translateX(100%);
  z-index: 2;
  padding: 0;
}

.SideBar ul li .AddToSalesForceModal ul li a {
  color: black;
  font-weight: normal;
  font-family: "Poppins", "Open Sans", sans-serif;
}

.SideBar ul li .AddToSalesForceModal ul li a:hover {
  color: rgb(54, 110, 179);
}

.AddToSalesForceModal .salesforce-modal-open-buttons {
  margin-left: 15rem;
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: min-content;
}

.AddToSalesForceModal .salesforce-modal-open-buttons .cancel {
  color: #109cf1;
  background: transparent;
  border: 2px solid rgb(201, 229, 249);
}

.AddToSalesForceModal .salesforce-modal-open-buttons .cancel:hover {
  color: #109cf1;
  background: transparent;
  border: 2px solid rgb(201, 229, 249);
}

.AddToSalesForceModal .salesforce-modal-open-textarea {
  border: white;
  margin: 1rem;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.AddToSalesForceModal .salesforce-modal-open-textarea .button {
  margin-right: 20rem;
}
