.AnalyticsCard .card-header {
  font-weight: bold;
  font-size: 0.8rem;
}

.AnalyticsCard .card-content {
  padding: 1.5rem 0;
}

.AnalyticsCard-stats {
  display: flex;
  align-items: center;
  justify-content: center;
}

.AnalyticsCard-stats .title {
  color: #757d8a;
  margin: 1.75rem 1.25rem;
  font-size: 4.25rem;
}

.AnalyticsCard-pos {
  color: #48c774;
}

.AnalyticsCard-neg {
  color: #f14668;
}

.AnalyticsCard .percentage {
  font-size: 1.5rem;
  margin: 0.5rem;
}

.card-content .poc-name {
  font-size: 0.8rem;
  width: 60%;
}

.card-content .poc-name .is-clipped {
  word-break: break-word;
}

.card-content .number-day {
  width: 20%;
  font-size: 0.75rem;
  text-align: center;
}

.analytics-overview .panel-heading {
  font-size: 16px;
  padding-bottom: 5px;
}

.analytics-overview .panel-heading .title-analytics {
  margin-bottom: 10px;
  text-align: center;
}

.analytics-overview .panel-heading .item-analytics {
  font-size: 0.75rem;
  font-weight: normal;
  margin-bottom: 4px;
}

.item-analytics .number-a {
  float: right;
}

.analytics-overview .panel-block-header {
  font-size: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.analytics-overview .head-ao {
  position: relative;
  text-align: center;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.analytics-overview .head-ao:after {
  content: "";
  position: absolute;
  right: -10px;
  top: 7px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
  clear: both;
}
