.popup-export {
  min-width: auto;
  padding-bottom: 0px !important;
}

.popup-export .title {
  margin-left: 9px !important;
}

.popup-export .top-bar-export-word {
  max-width: 600px;
}

.popup-export .checkbox-group {
  display: flex;
  flex-wrap: wrap;
}

.popup-export .checkbox-item {
  margin-bottom: 8px;
  width: 50%;
  flex-grow: 2;
}

.popup-export .checkbox-all {
  margin-bottom: 20px;
}

.popup-export input[type="checkbox"] {
  cursor: pointer;
}

.popup-export .spin-export {
  margin-right: 10px;
  margin-bottom: 2px;
}

.popup-export .spin-export .is-spinning {
  font-size: 20px;
}

.popup-export .select-filter {
  min-width: 180px;
  text-align: left !important;
}

.popup-export .dropdown-menu {
  min-width: 180px;
}

.popup-export .is-light {
  width: unset !important;
}
