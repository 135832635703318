.PocUseCases-container {
  background-color: white;
  border-radius: 0.25rem;
  position: relative;
  min-height: 1rem;
}

.PocUseCases-table thead tr,
.PocUseCases-table tbody tr {
  border: 1px solid rgb(240, 242, 243);
}

.PocUseCases-table thead tr th,
.PocUseCases-table tbody tr td {
  border: none;
}

.Usecases-table thead .Usage-header,
.Usecases-table thead .Win-Rate-header,
.NestedSuccessCriteriaTable thead .Usage-header,
.NestedSuccessCriteriaTable thead .Win-Rate-header {
  width: 15%;
}

.NestedSuccessCriteriaTable thead .Success-Criteria-header {
  width: 55%;
}

.NestedSuccessCriteriaTable thead tr th:first-child,
.NestedSuccessCriteriaTable tbody tr td:first-child {
  padding-left: 10%;
  width: 15%;
}

.NestedSuccessCriteriaTable thead tr,
.NestedSuccessCriteriaTable tbody tr {
  border: none;
}

.NestedSuccessCriteriaTable tbody tr {
  border-top: 1px solid rgb(240, 242, 243);
}

.PocUseCasesBulkAttach-button {
  position: absolute;
  right: 0.5rem;
  bottom: -4rem;
}

.PocUseCasesBulkAttach tr.active {
  background-color: rgb(243, 249, 254);
}

.UseCaseCard-table tr td,
.UseCaseCard-table tr td .form-control {
  color: rgb(113, 118, 130);
}

/* .UseCaseCard-table .Assignee-header,
.UseCaseCard-table .Importance-header,
.UseCaseCard-table .Outcome-header {
  width: 10rem;
}

.UseCaseCard-table .Due-Date-header {
  width: 8rem;
} */

.PillSelector {
  display: flex;
  background: rgb(239, 239, 239);
  border-radius: 0.5rem;
  padding: 0.5rem;
  box-shadow: inset 0px 1.1px 1.18px rgb(0 0 0 / 25%),
    inset 0px 0.55px 1.09px rgb(0 0 0 / 25%);
  justify-content: space-around;
}

.PillSelector li {
  color: rgb(97, 97, 97);
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  flex: 1;
  text-align: center;
}

.PillSelector li.active {
  background: rgb(220, 230, 244);
  color: rgb(51, 116, 228);
  font-weight: bold;
}

.PocUseCases-table > :not(:first-child) {
  border: none;
}

.recommended-usecase {
  background: #ffffff;
  border: 1px solid #109cf1;
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;

  display: flex;
  align-items: center;

  color: #109cf1;
}

.poc-phase-steps .stepper__item.active:before,
.poc-phase-steps .stepper__item.completed:before {
  outline: 2px solid #109cf1;
  color: #109cf1;
  font-weight: 600;
}

.poc-phase-steps .stepper__item.completed:after {
  background-color: #109cf1;
}

.poc-phase-steps .stepper__title {
  margin-bottom: 0.5rem;
  color: #323c47;
}

.select.disabled::after {
  content: none !important;
}

.select-filter {
  padding-right: 30px;
  min-width: 200px;
  justify-content: start;
}

.select-filter .dropdown-trigger {
  width: 100%;
  position: relative;
}

.select-filter .dropdown-trigger .ml-2 {
  margin-top: 4px;
  position: absolute;
  right: -20px;
}

.dropdown-content {
  text-align: left;
}

.filter-scope {
  margin-left: 20px;
}
