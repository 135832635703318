.GettingStarted {
  background-color: rgb(240, 245, 250);
  font-family: "Poppins", "Open Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
}

.GettingStarted .title {
  color: #109cf1;
  font-weight: 500;
}
