.ModalLinkExternal.table.Table thead th {
  color: rgb(128, 128, 128);
  text-transform: uppercase;
}

.ModalLinkExternal tbody tr {
  border: 1px solid rgb(235, 236, 240);
}

.ModalLinkExternal thead th:first-child {
  width: 2.5rem;
}

.ModalLinkExternal .Id-header {
  width: 7rem;
}

.ModalLinkExternal .POCs-header,
.ModalLinkExternal thead th:last-child {
  width: 6rem;
}

.ModalLinkExternal .Status-header,
.ModalLinkExternal .ACV-header {
  width: 8rem;
}
