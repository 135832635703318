.autocomplete-container {
  background: #ffffff;
  border: 1px solid #dddbdb;
  max-height: 150px;
  overflow-x: hidden;
}
.autocomplete-container button {
  margin-top: 2em;
  margin-bottom: 2em;
}
.list-group-item {
  line-height: 150%;
  cursor: pointer !important;
  padding: 0.1em 0.5em;
}
.list-group-item:hover {
  background-color: #e3e3e3;
}
.list-group-item.invite-new {
  margin-top: 1em;
  border-top: 1px solid #dddbdb;
  padding: 0.5em;
}
