.FeedbackIssue-header > * {
  margin: 0 1em;
  font-family: "Poppins", "Open Sans", sans-serif;
}

.FeedbackIssue-header {
  align-items: center;
}

.Feedback-issue-table .Account-Name-header {
  width: 10rem;
}

.Feedback-issue-table .Sales-Engineer-header {
  width: 10rem;
}

.Feedback-issue-table .ACV-header {
  width: 7rem;
}

.Feedback-issue-table .Priority-header {
  width: 7rem;
}

.Feedback-issue-table .Due-Date-header {
  width: 7rem;
}

.Feedback-issue-table .POC-Close-Date-header {
  width: 10rem;
}
