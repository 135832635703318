.table .progress-container progress {
  height: 0.35rem;
}

.table .border-left-success {
  border-left: 3px solid #48c78e;
}
.table .border-left-warning {
  border-left: 3px solid #ffb946;
}
.table .border-left-danger {
  border-left: 3px solid #f14668;
}
